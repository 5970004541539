import { WalletSource } from "../../../lib/types/UserSession";
import CopyIcon from "./CopyIcon";


export default function AddressBoxComponent(props: {address: string | null | undefined, type?: 'email' | 'wallet', wallet_source?: WalletSource}) {

    function truncateString(s: string, untruncatedChars: number) {
        if (s.length <= 2 * untruncatedChars) {
            return s;  // Return the original string if it's too short to truncate
        }

        const start = s.slice(0, untruncatedChars);
        const end = s.slice(-untruncatedChars);
        return `${start}************${end}`;
    }

    const _untruncated = (props.address ?? '')
                            .replace('ETHEREUM:', '')
                            .replace('POLYGON:', '')
                            .replace('SOLANA:', '')
  
    const _address = truncateString(_untruncated, 7);


    const CopyAddressToClipboard = () => {
        return(
            <button className="w-8 h-8 rounded-md" onClick={() => {
                // copy _address : props.address to clipboard
                navigator.clipboard.writeText(_untruncated ?? '');
            }}> 
                <CopyIcon className="w-4 h-4" />
            </button>
        )
    }


    const TextBoxLabel = (props: React.PropsWithChildren) => {
        return(
            <label
                className="block rounded-lg text-center text-sm font-medium text-gray-700 mb-1 truncate smaller-on-mobile"
                style={{ alignSelf: 'center', alignItems: 'center', display: 'flex', gap: '16px', paddingTop: '1px', paddingBottom: '1px' }}
            >
                {props.children}
            </label>
        )
    }


    const TextBox = (props: React.PropsWithChildren) => {
        return(
            <div 
                className="bg-muted rounded-lg flex items-flex-start justify-center" 
                style={{ flexDirection: 'column', paddingTop: '1px', paddingBottom: '1px' }}
            >
                <TextBoxLabel>
                    {props.children}
                </TextBoxLabel>
            </div>
        )
    }

    
    if (props.wallet_source) {
        const background = () => {
            if (props.wallet_source?.custody === 'crossmint') {
                return 'bg-green-100';
            }
            return 'bg-muted';
        }

        const SideLink = () => {
            if (props.wallet_source?.custody === 'crossmint') {
                return(<p className="text-sm text-center" 
                    style={{ alignSelf: 'center' }}>Managed by MTD through <a href={"https://www.crossmint.com/signin?callbackUrl=/console/collections"}>Crossmint</a></p>)
            }
            return(<></>);
        }
        
        return(
            <div 
                className={`${background()} p-1 rounded-lg`}
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    minWidth:'100%', 
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '5px'
                }}
            >
                <SideLink/>
                <TextBoxLabel>
                    {props.type === 'wallet' ? _address : props.address} 
                    <CopyAddressToClipboard />
                </TextBoxLabel>
                {/* <label 
                    className="block rounded-lg text-center text-sm font-medium text-gray-700 mb-1 smaller-on-mobile"
                    style={{ alignSelf: 'center', alignItems: 'center', display: 'flex', gap: '16px' }}
                >
                </label> */}
            </div>
        )
    }

    if (props.type === 'email') {
        return (
            <div className="grid gap-4">
                <TextBox>{props.address}</TextBox>
            </div>
        )
    }
    return (
      <div className="grid gap-4">
        <div className="bg-muted rounded-lg flex items-flex-start justify-center" style={{ flexDirection: 'row' }}>
            <TextBox>
                {_address}
                <CopyAddressToClipboard />
            </TextBox>
        </div>
      </div>
    )
  }