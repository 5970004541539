import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { LoginStates } from "../../lib/types/LoginStates";
import UserSession, { WalletSource } from "../../lib/types/UserSession";
import { Container } from "./Container";
import AddressBoxComponent from "./utilities/WalletDisplay";
import ToLogin from "./ToLogin";


export default function ConnectWalletForm(props: {
    user: UserSession | null;
    nav_to?: Dispatch<SetStateAction<LoginStates>>;
  }) {

    const [accepts_managed_wallet, setAcceptsManagedWallet] = useState(false);

    const [accepts_add_personal, setAcceptsAddPersonal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [managedWallet, setManagedWallet] = useState<WalletSource | undefined>(undefined);

    const [personalWallets, setPersonalWallets] = useState<WalletSource[]>([]);

    const makeCreateManaged = () => {
        
    }

    useEffect(() => {
        if (!props.user) {
            return;
        }

        const managed = props.user?.wallet_sources?.find((wallet) => wallet.eoa === false && wallet.custody !== 'self');
        setManagedWallet(managed);

        const personal = props.user?.wallet_sources?.filter((wallet) => wallet.eoa === true && wallet.custody === 'self') ?? [];

        setPersonalWallets(personal);

    },[props.user, props.nav_to])

    /**
     * Get Wallets
     */

    const Header = () => {
        return (
            <div style={{
                width: '100%', 
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
            }}>
                <h1 className="text-2xl font-bold">My Wallets</h1>
                <button className="px-4 bg-green-500 text-white rounded-md"><b>+ Add</b></button>
            </div>
        )
    }

    const CreateManagedWalletBlock = useCallback(() => {
        return(
            <>
            </>
        )
    }, [])

    const NoWalletsDisplay = useCallback(() => {
        return (
            <>
            </>
        )
    }, [accepts_managed_wallet, setAcceptsManagedWallet])


    const DisplayWalletList = () => {

        const ManagedWallets = () => {
            if (props.user?.wallet_sources && props.user?.wallet_sources?.length > 0) {
                const wallets = props.user.wallet_sources.filter((wallet) => wallet.custody !== 'self');
                return(
                    <>
                        <div className="flex flex-row justify-between">
                            <h1 className="text-xl font-bold">Managed Wallets</h1>
                        </div>
                        <div>
                            <div className="flex flex-row justify-between">
                                <AddressBoxComponent address={wallets[0].address} type="wallet" wallet_source={wallets[0]} />
                            </div>
                        </div>
                    </>
                )
            }
            return(<></>)
        }
        return(
            <div style={{
                width: '100%', 
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                gap: '8px'
            }}>
                <ManagedWallets />
                <h1 className="text-xl font-bold">Personal Wallets</h1>
            </div>
        )
    }


    const ManagedWallet = useCallback(() => {


        const ManagedWalletHeader = () => {
            if (!managedWallet) {
                return(
                    <>
                        <div className="flex flex-row justify-between column-on-mobile">
                            <h1 className="text-xl font-bold">Managed Wallets</h1>
                            {!managedWallet && <p className="italic text-sm align-self-center">Recommended</p>}
                        </div> 
                        <div className="px-4 bg-green-100 text-green-900 rounded-md no-wallets-container">
                            <p className="p-2 text-sm font-bold">MTD can create and manage a single wallet for you by using your email address for your activites on the platform. 
                            <br></br><b><i>Managed wallets cannot be exported out of MTD and can only hold NFT's.</i></b></p>
                        </div>
                    </>
                )
            }
            return(
                <div className="flex flex-row justify-between">
                    <h1 className="text-xl font-bold">Managed Wallets</h1>
                </div> 
            )
        }

        if (!managedWallet) {
            return(
                <div style={{display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '4px'}}>
                    <ManagedWalletHeader />
                    {/* Display a create me a Crossmint Wallet  */}
                    <div style={{
                        width: '100%', 
                        flexDirection: 'row', 
                        display: 'flex', 
                        justifyContent: 'center', 
                    }}>
                        <input
                            type="checkbox"
                            // className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                            id="ack_risk_consent"
                            checked={accepts_managed_wallet}
                            onChange={() => setAcceptsManagedWallet(!accepts_managed_wallet)}
                            style={{ marginRight: 10, width: "20px", height: "20px", padding: 0 }}
                            required={true}
                        />
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            You may create a wallet with my email address
                        </label>
                    </div>
                    <button
                        type="submit"
                        disabled={!accepts_managed_wallet}
                        style={{opacity: !accepts_managed_wallet ? 0.5 : 1, cursor: !accepts_managed_wallet ? 'not-allowed' : 'pointer'}}
                        className={
                        `w-full bg-green-500 ${!accepts_managed_wallet ? '' : 'hover:bg-green-600 '}text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center`
                        }
                        onClick={makeCreateManaged}
                    >
                        {loading ? (
                            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
                        ) : (
                            'Create a Managed Wallet'
                        )}
                    </button>
                </div>
            )
        }

        return(
            <div style={{display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '4px'}}>
                <ManagedWalletHeader />
                <AddressBoxComponent address={managedWallet.address} type={'wallet'} wallet_source={managedWallet} />
            </div>
        )

    }, [managedWallet, accepts_managed_wallet, setAcceptsManagedWallet]);




    const PersonalWallets = useCallback(() => {

        const PersonalWalletsHeader = () => {
            return(
                <div className="flex flex-row justify-between  column-on-mobile">
                    <h1 className="text-xl font-bold">Personal Wallets</h1>
                    <p className="text-sm align-self-center"><i>Self-Custodial</i></p>
                </div> 
            )
        }

        if (personalWallets.length === 0) {
            return(
                <div style={{display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '4px', marginTop: '16px'}}>
                    <PersonalWalletsHeader />
                    <div className="px-4 bg-gray-100 text-gray-500 rounded-md no-wallets-container">
                        <p className="p-2 text-m font-bold"> 
                            <b><i>Warning: Personal Wallets cannot be managed by MTD!</i> Using Personal Wallets puts you in full control of ownership of your wallet, you are responsible for any loss or theft of your collectibles sent to Personal Wallets. Only use Personal Wallets if you are comfortable with this risk, and always do your own research.</b>
                        </p>
                    </div>
                    <div style={{
                        width: '100%', 
                        flexDirection: 'row', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        // padding: '10px'
                    }}>
                        <input
                            type="checkbox"
                            // className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                            id="ack_risk_self_custody"
                            checked={accepts_add_personal}
                            onChange={() => setAcceptsAddPersonal(!accepts_add_personal)}
                            style={{ marginRight: 10, width: "20px", height: "20px", padding: 0 }}
                            required={true}
                        />
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            I understand the risks
                        </label>
                    </div>
                    <button
                        type="submit"
                        disabled={!accepts_add_personal}
                        style={{opacity: !accepts_add_personal ? 0.5 : 1, cursor: !accepts_add_personal ? 'not-allowed' : 'pointer'}}
                        className={
                        `w-full bg-green-500 ${!accepts_add_personal ? '' : 'hover:bg-green-600 '}text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center`
                        }
                        onClick={makeCreateManaged}
                    >
                        {loading ? (
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
                        ) : (
                        'Add a Personal Wallet'
                        )}
                    </button>
                </div>
            )
        }

        return(
            <div style={{display: 'flex', flexDirection: 'column', gap: '16px', marginBottom: '4px', marginTop: '16px'}}>
                <PersonalWalletsHeader />
                {
                    personalWallets.map((wallet) => 
                        <AddressBoxComponent address={wallet.address} type={'wallet'} wallet_source={wallet} />
                    )
                }
            </div>
        )

    }, [personalWallets, accepts_add_personal]);
    

    return(
        <Container>
            {/* <Header /> */}
            <ManagedWallet />
            <PersonalWallets />
             <ToLogin nav_to={props.nav_to}/>
        </Container>
    )
}