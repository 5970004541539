import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Container } from './Container';
import { LoginStates } from '../../lib/types/LoginStates';
import RedeemResponse from '../../lib/types/RedeemResponse';
import UserSession from '../../lib/types/UserSession';
import URLMaker from '../../lib/url';
import LogOut from './LogOut';
import AddressBoxComponent from './utilities/WalletDisplay';


/**
 * v0 by Vercel.
 * @see https://v0.dev/t/j7hyfiW0mb6
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

function capitalizeString(s: string) {
  return s.replace(/\b\w/g, function(char: string) {
      return char.toUpperCase();
  });
}

export default function RedeemForm(props: {
  handleSetRedeemed: Dispatch<SetStateAction<RedeemResponse>>;
  user: UserSession | null;
  nav_to?: Dispatch<SetStateAction<LoginStates>>;
}) {
  const [message, setMessage] = useState<string>('');

  const [wallet_address, setWalletAddress] = useState<string | undefined>();

  const [email, setEmail] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const [display_risk_consent, setDisplayRiskConsent] = useState(true);

  const [wallet_custody, setWalletCustody] = useState<string | null>(null);

  const [accepts_risk, setAcceptsRisk] = useState(false);

  const loginDomain = URLMaker();

  useMemo(() => {
    if (!props.user) {
      return;
    }
    if (props.user?.auth?.preferredWallet) {
      console.log("Setting wallet address to preferred wallet");
      setWalletAddress(props.user.auth.preferredWallet);
    } else if (props.user.wallets.length > 0) {
      console.log("Setting wallet address to first wallet");
      setWalletAddress(props.user.wallets[0]);
    } else if (props.user.auth?.userEmail) {
      console.log("Setting wallet address to email");
      setEmail(props.user.auth.userEmail);
      setDisplayRiskConsent(false);
    }
  }, [props.user, setEmail, setWalletAddress, setDisplayRiskConsent]);

  useEffect(() => {
    if (wallet_address && props.user?.wallet_sources) {
      const wallet = props.user.wallet_sources.find((wallet) => wallet.address === wallet_address);
      if (wallet) {
        setDisplayRiskConsent(wallet.eoa === true);
        setWalletCustody(wallet.custody);
      }
    }
  }, [props.user, wallet_address]);

  const handleRedeem = (e: { preventDefault: () => void }) => {
    const run = async () => {
      try {
        // grab the form information
        const productCode = (
          document.getElementById('redemption_code') as HTMLInputElement
        ).value;

        interface RedeemRequest {
          productCode: string;
          type?: 'email' | 'wallet';
          address?: string;
        }

        let redeem: RedeemRequest = {
          productCode,
        };

        if (!props.user) {
          setMessage('User not set');
          return;
        }

        const { auth, wallets } = props.user;

        if (auth?.preferredWallet) {
          redeem = {
            ...redeem,
            type: 'wallet',
            address: auth.preferredWallet,
          };
        } else if (wallets.length > 0) {
          redeem = {
            ...redeem,
            type: 'wallet',
            address: wallets[0],
          };
        } else {
          redeem = {
            ...redeem,
            type: 'email',
            address: auth?.userEmail,
          };
        }

        // run a series of rule checks that verifies all of the properties are set for the redeem request
        if (!redeem.productCode) {
          setMessage('Product Code is required');
          return;
        }

        if (!['email', 'wallet'].includes(redeem.type ?? '')) {
          setMessage('Invalid body - type not specified');
          return;
        }

        if (!redeem.address) {
          setMessage('Invalid body - address not set');
          return;
        }

        const body = JSON.stringify(redeem);

        const response = await fetch(`${loginDomain}/products/unity/redeem`, {
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.user.auth?.authToken}`,
          },
          method: 'POST',
          mode: 'cors',
        });

        if (!response.ok) {
          const t = await response.text();

          // @ts-ignore
          setMessage(t);
          return;
        }

        const data = (await response.json()) as RedeemResponse;

        if (props.nav_to) {
          props.nav_to(LoginStates.CONFIRMED_REDEMPTION);
        }
        props.handleSetRedeemed(data);
      } catch (err) {
        console.error(err);
        // @ts-ignore
        setMessage(err ?? 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
    e.preventDefault();
    setLoading(true);
    run();
  };

  const ErrorMessageDiv = useCallback(
    () => <p className="text-red-500 text-center">{message}</p>,
    [message],
  );

  const AddressBlock = useCallback(() => {
    return(
      <AddressBoxComponent address={wallet_address ?? email} type={wallet_address ? 'wallet' : 'email'}/>
    )
  }, [wallet_address, email])

  const ClickOutIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="16"
      height="16"
      fill="currentColor"
      className="click-out-icon"
    >
      <path d="M14 3h7v7h-2V6.41l-9.29 9.3-1.42-1.42 9.3-9.3H14V3zM5 5v14h14v-5h2v7H3V3h7v2H5z" />
    </svg>
  );

  const ExternalLink = (props: React.PropsWithChildren & { href: string }) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer" className="external-link">
      {props.children} <ClickOutIcon />
    </a>
  );
  

  const DisplayBasedOnRiskConsent = useCallback(() => {
    const WarningText = () => {
      if (wallet_custody === 'self') {
        return(
          <label className="block rounded-lg text-sm font-medium text-gray-700 mb-1 bg-yellow-100 p-3">
            <b><i>You are redeeming to a Personal Wallet, MTD does not manage this wallet for you! MTD is not responsible for any collectibles or assets that are lost when sent or redeemed to personal wallets.</i></b>
          </label>
        );
      }

      if (wallet_custody === 'crossmint' && display_risk_consent === false) {
        return(
          // TODO: Add a link to the Crossmint wallet, and a checkmark
          <label className="block rounded-lg text-sm font-medium text-gray-700 mb-1 bg-green-100 p-3">
            You are redeeming to a Managed Wallet, you can access it on {wallet_custody === 'crossmint' ? <ExternalLink href={'https://www.crossmint.com/signin?callbackUrl=/user/collection'}>{capitalizeString('Crossmint')}</ExternalLink> : capitalizeString('Crossmint')}.
          </label>
        );
      }
      
      if (!wallet_custody && !wallet_address) {
        return(
          // TODO: Add a link to the Crossmint wallet, and a checkmark
          <label className="block rounded-lg text-sm font-medium text-gray-700 mb-1 bg-green-100 p-3">
            A wallet managed by us will be created for you when you redeem a code for the first time. Visit <ExternalLink href={'https://www.crossmint.com/signin?callbackUrl=/user/collection'}>{capitalizeString('Crossmint')}</ExternalLink> with your email address above once you have redeemed the collectible.
          </label>
        );
      }
      /**
       * Grey area warn the user to be careful
       */
      return(
        <label className="block rounded-lg text-sm font-medium text-gray-700 mb-1 bg-yellow-100 p-3">
          You are redeeming this collectible to a wallet that McFarlane Toys Digital may not control. Make sure you have access to this address before proceeding. <i><b>McFarlane Toys Digital is not responsible for any collectibles or assets who are lost when sent or redeemed to personal wallets</b></i>.
        </label>
      );
    }
    if (display_risk_consent === true) {
      return(
        <div className="mt-8">
          <WarningText />
          <div style={{width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'center', padding: '10px'}}>
            <input
              type="checkbox"
              id="ack_display_risk_consent"
              checked={accepts_risk}
              onChange={() => setAcceptsRisk(!accepts_risk)}
              style={{ marginRight: 10, width: "20px", height: "20px", padding: 0 }}
              required={true}
            />
            <label className="block text-sm font-medium text-gray-700 mb-1">
              I understand
            </label>
          </div>
          <button
            type="submit"
            disabled={!accepts_risk || loading}
            style={{opacity: !accepts_risk ? 0.5 : 1, cursor: !accepts_risk ? 'not-allowed' : 'pointer'}}
            className={
              `w-full bg-green-500 ${!accepts_risk ? '' : 'hover:bg-green-600 '}text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center`
            }
          >
            {loading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
            ) : (
              'Redeem'
            )}
          </button>
        </div>
      )
    }
    return(
      <div className="mt-8">
        <WarningText />
        <button
          type="submit"
          disabled={loading}
          style={{marginTop: '15px'}}
          className="w-full bg-green-500 hover:bg-green-600 text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center"
        >
          {loading ? (
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
          ) : (
            'Redeem'
          )}
        </button>
      </div>
    )
  }, [display_risk_consent, accepts_risk, wallet_custody])

  return (
    <Container>
      <h1 className="text-3xl font-oswald font-bold mb-2 text-center">
        Redeem a Code
      </h1>
      <div>
        <ErrorMessageDiv />
      </div>
      <form className="space-y-4" onSubmit={handleRedeem}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Redemption Code
          </label>
          <input
            id="redemption_code"
            placeholder="Enter a redemption code"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Your collectible will be sent to the following {wallet_address ? 'wallet' : 'email'} address
        </label>
        <AddressBlock />
        <DisplayBasedOnRiskConsent />
      </form>
      <LogOut {...props}/>
    </Container>
  );
}
