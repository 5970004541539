import { getSessionCookie } from '../session';
import { UserSession } from '../types';
import { User } from '../types/UserSession';
import URLMaker from '../url';

/**
 * Handles loging in the user and receiving a session token
 *
 * This also sets the token to the session cookie
 *
 * @param username
 * @param password
 * @returns
 */
  //@ts-ignore
async function pingUSer(): Promise<UserSession> {

  const loginDomain = URLMaker();

  const auth = getSessionCookie();

  const headers = { 'Content-Type': 'application/json', };

  if (!auth) {
    throw new Error('No session token');
  }

  //@ts-ignore
  headers['Authorization'] = 'Bearer ' + auth;

  const response = await fetch(
    `${loginDomain}/users/profile`,
    {
    //   body,
      headers,
      method: 'GET',
      mode: 'cors',
    },
  );

  if (!response.ok) {
    const t = await response.text();
    throw new Error(t);
  }

    
  //@ts-ignore
  const data = (await response.json()) as UserSession;

  return data;
}

export default pingUSer;
