export enum LoginStates {
  LOGGED_IN, // TODO
  LOGGED_OUT, // NEED TO DO THE CREATE OPTION
  REDEEMING, // TODO
  CONFIRMED_REDEMPTION, // TODO
  CREATING_ACCOUNT, // TODO
}

export enum LoggedInTabs {
  REDEEM_CODE,
  ADD_WALLET,
}